import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

  private regex: RegExp = new RegExp(/^[0-9]*$/);
  private maxLength: number = 6;

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '');

    // Limit to maxLength
    if (value.length > this.maxLength) {
      value = value.substring(0, this.maxLength);
    }

    // Update the value in the form control
    if (this.ngControl.control) {
      this.ngControl.control.setValue(value);
    }
  }
}