import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { PreResetPasswordModel } from "../models/pre-reset-password.model";
import { ResetPasswordModel } from "../models/reset-password.model";
import { AppService } from "../app.service";
import { ResetPasswordResultModel } from "../models/reset-password-result.model";
import { PreResetPasswordResultModel } from "../models/pre-reset-password-result.model";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordService {
  constructor(private httpClient: HttpClient, private appService: AppService) {}

  resetPassword(
    resetPassword: ResetPasswordModel
  ): Observable<ResetPasswordResultModel> {
    resetPassword.password = this.appService.aesEncrypt(
      resetPassword.userName,
      resetPassword.password
    );
    resetPassword.confirmPassword = this.appService.aesEncrypt(
      resetPassword.userName,
      resetPassword.confirmPassword
    );
    if (resetPassword.otp) {
      resetPassword.otp = this.appService.aesEncrypt(
        resetPassword.userName,
        resetPassword.otp
      );
    }
    return this.httpClient.post<ResetPasswordResultModel>(
      "/Account/ResetPassword",
      resetPassword
    ).pipe(
      catchError((error) => {
        this.appService.errorObject = [error.error];
        window.location.href = this.appService.errorObject[0].returnUrl;
        return throwError(error);
      })
    );;
  }
  preResetPassword(
    preResetPassword: PreResetPasswordModel
  ): Observable<PreResetPasswordResultModel> {
    return this.httpClient.post<PreResetPasswordResultModel>(
      "/Account/PreResetPassword",
      preResetPassword
    );
  }
}
