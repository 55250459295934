<div class="mb-1  mt-1 large">
  {{'Hello' | translate}} {{username}}!
</div>
<div class="mb-3 medium">
  {{'Reset_Password.ResetPasswordText' | translate}}
</div>
<div *ngIf="count > 0 && shouldloadForm">
  <div class = "mt-1 small">
      {{ 'ClickOn' | translate}}  {{ 'MULTI_FACTOR.ResendOtp' | translate}}  {{ 'MULTI_FACTOR.ResendOtpMessage' | translate}} <span class="bold-text">{{count}}</span> {{ 'Seconds' | translate}}
  </div>
</div>

<form *ngIf = "shouldloadForm" [formGroup]="resetPasswordForm" class="form-floating" #form="ngForm" autocomplete="off">
  <div class="form-floating mt-2 mb-3">
    <input id="otp" type="password" class="form-control" formControlName="otp" appNumberOnly
      [ngClass]="{ 'is-valid': formControls.otp?.valid && (formControls.newPassword.dirty || formControls.newPassword.touched), 'is-invalid': formControls.otp?.errors?.required && (formControls.otp?.touched  || formControls.otp?.dirty) && form.submitted}"
      placeholder="test" (keydown.space)="$event.preventDefault();">

    <label for="otp">{{'Reset_Password.OTP' | translate}} </label>
    <div class="invalid-feedback small text-justify red-text">
      <div *ngIf="formControls.otp?.errors?.required" aria-invalid="true" role="alert">
          {{'Reset_Password.OTPRequired' | translate}}
      </div>
    </div>
  </div>
  <div class="form-floating mb-4">
    <input id="newPassword" (focus)="onFocus()" (blur)="onBlur()"  autofocus [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="newPassword"
      [ngClass]="{ 'is-invalid': (formControls.newPassword.dirty || formControls.newPassword.touched) && form.submitted && (form.errors?.noLength && form.errors?.noNumber&& form.errors?.noSpecial && form.errors?.noUpper && form.errors?.noLower)
              && formControls.newPassword.invalid , 'is-valid': (formControls.newPassword.dirty || formControls.newPassword.touched) && formControls.newPassword.valid } " placeholder="test"
              (keydown.space)="$event.preventDefault();"
              [attr.aria-invalid] = "(formControls.newPassword.dirty || formControls.newPassword.touched ) && form.submitted && (form.errors?.noLength && form.errors?.noNumber&& form.errors?.noSpecial && form.errors?.noUpper && form.errors?.noLower)
              && formControls.newPassword.invalid">
    <label for="newPassword">{{'Change_Password.NewPassword' | translate}} </label>
    <div class="invalid-feedback small text-justify position-absolute">
      <div *ngIf="formControls.newPassword.errors?.required" aria-invalid="true" role="alert">
        {{'Change_Password.NewPasswordRequired' | translate}}
      </div>
    </div>
    <div class="eyetriangle">
      <span class="px-2">
        <i><fa-icon class = "red-text" [icon]="Triangle" *ngIf="(form.errors?.noUpper ||  form.errors?.noLower || form.errors?.noSpecial || form.errors?.noLength || form.errors?.noNumber)&& (formControls.newPassword.touched ||formControls.newPassword.dirty) && hasFocus == true"></fa-icon>  </i>
    </span>
    <fa-icon class = "eye" [icon]="Eye" (click)="togglePassword()" [class.hide]="!showPassword "></fa-icon>
      <fa-icon class = "eye" [icon]="EyeSlash" (click)="togglePassword()" [class.hide]="showPassword"></fa-icon>

    </div>
  </div>

  <div class="passvaltext" *ngIf=' (form.errors?.noUpper ||  form.errors?.noLower || form.errors?.noSpecial || form.errors?.noLength || form.errors?.noNumber)&& (formControls.newPassword.touched ||formControls.newPassword.dirty) && (hasFocus)'>
    <div>
      <div class="my-1" aria-invalid="true" role="alert">
        <span class="small "
          [ngClass]="{'green-text': !form.errors?.noLength,'red-text': form.errors?.noLength}">
          <span *ngIf="form.errors?.noLength"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noLength"> <fa-icon [icon]="Tick"></fa-icon></span>
          {{'Validations.noLength' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small "
          [ngClass]="{'green-text': !form.errors?.noNumber,'red-text': form.errors?.noNumber}">
          <span *ngIf="form.errors?.noNumber"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noNumber"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noNumber' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small "
          [ngClass]="{'green-text': !form.errors?.noUpper,'red-text': form.errors?.noUpper}">
          <span *ngIf="form.errors?.noUpper"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noUpper"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noUpper' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small"
          [ngClass]="{'green-text': !form.errors?.noLower,'red-text': form.errors?.noLower}">
          <span *ngIf="form.errors?.noLower"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noLower"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noLower' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small"
          [ngClass]="{'green-text': !form.errors?.noSpecial,'red-text': form.errors?.noSpecial}">
          <span *ngIf="form.errors?.noSpecial"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noSpecial"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noSpecial' | translate}}
        </span>
      </div>
    </div>
  </div>
  <div class="form-floating mb-3">
    <input id="confirmPassword" type="password" class="form-control" formControlName="confirmPassword"
      [ngClass]="{ 'is-valid': formControls.confirmPassword.valid && (formControls.newPassword.dirty || formControls.newPassword.touched), 'is-invalid': (formControls.confirmPassword.dirty || formControls.confirmPassword.touched ) && form.submitted  && (form.errors?.notEqual && formControls.confirmPassword.touched ) } "
      placeholder="test" (keydown.space)="$event.preventDefault();"
      [attr.aria-invalid]="formControls.confirmPassword.valid && (formControls.newPassword.dirty || formControls.newPassword.touched)">

    <label for="confirmPassword">{{'Reset_Password.ConfirmNewPassword' | translate}} </label>
    <div class="invalid-feedback small text-justify position-absolute red-text">
      <br>
      <div *ngIf="formControls.confirmPassword.errors?.required" aria-invalid="true" role="alert">
          {{'Reset_Password.ConfirmPasswordRequired' | translate}}
      </div>
    </div>
    <div *ngIf = "(formControls.confirmPassword.dirty || formControls.confirmPassword.touched ) && form.submitted && resetPasswordForm.errors?.notEqual" aria-invalid="true" role="alert">
      <div class="small position-absolute red-text">
        {{ 'Reset_Password.PasswordsNotMatched' | translate}}
      </div>
    </div>
  </div>
  <div class="red-text mt-2 small text-justify">
    <div *ngIf="resetPasswordForm.errors?.passwordResetFailed" aria-invalid="true" role="alert">
      {{'Reset_Password.PasswordResetFailed' |translate}}
    </div>
    <div *ngIf="resetPasswordForm.errors?.passwordReused" aria-invalid="true" role="alert">
      {{'Reset_Password.PasswordReused' | translate}}
    </div>
    <div *ngIf="resetPasswordForm.errors?.passwordMismatch" aria-invalid="true" role="alert">
      {{'Reset_Password.PasswordsNotMatched' | translate}}
    </div>
  </div>
  <div class="d-flex align-items-center  mt-5 ">
    <button type="submit" class="btn btn-secondary" style="margin-right: 4%;" (click)="resendVerificationCode()" [disabled]="count != 0? 'disabled': null">
      {{'MULTI_FACTOR.ResendOtp' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" style="width: 40%" (click) = "resetPassword()">
      {{'Update' | translate}}
    </button>
  </div>
</form>

<ng-template #infoTemplate>
  <div class="modal-body pb-0 small">
    <div class="d-flex align-items-center mb-2">
    <span class="modal-message-icon"><fa-icon class="red-text" [icon]="Cross "></fa-icon></span>
      <span class="large px-2">{{ messageTitle | translate}}</span>
    </div>
    <p class="mt-2">{{ messageText | translate}}</p>
  </div>
  <div class="modal-footer pt-0 small">
    <button type="button" class="btn btn-primary" (click)="alertOk()" >{{'OK' |translate}}</button>
  </div>
</ng-template>

