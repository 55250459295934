//Angular
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//3rd party
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TabsModule } from 'ngx-bootstrap/tabs';

//application
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { FooterComponent } from './footer/footer.component';
import { FindUserComponent } from './find-user/find-user.component';
import { FindOrganizationComponent } from './find-organization/find-organization.component';
import { FirstTimeUserComponent } from './first-time-user/first-time-user.component';
import { SupplierRegistrationComponent } from './supplier-registration/supplier-registration.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlagsComponent } from './flags/flags.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ForgetCredentialComponent } from './forget-credential/forget-credential.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { UsernameValidationDirective } from './username-validation.directive';
import { LoadingInterceptor } from './loading.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AutofocusDirective } from './autofocus.directive';
import { LayoutViewModel } from './models/layout-view.model';
import { ToastrModule } from 'ngx-toastr';
import { MultiFactorComponent } from './multi-factor/multi-factor.component';
import { SelectPartnerComponent } from './select-partner/select-partner.component';
import { CookieService } from 'ngx-cookie-service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AuthenticatorComponent } from './authenticator/authenticator.component'
import { QRCodeModule } from 'angularx-qrcode';
import { NumberOnlyDirective } from './number-only.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    FooterComponent,
    FindUserComponent,
    FindOrganizationComponent,
    FirstTimeUserComponent,
    SupplierRegistrationComponent,
    FlagsComponent,
    ChangepasswordComponent,
    ForgetCredentialComponent,
    UsernameValidationDirective,
    ResetPasswordComponent,
    AutofocusDirective,
    MultiFactorComponent,
    SelectPartnerComponent,
    AuthenticatorComponent,
    NumberOnlyDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    ButtonsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    SelectDropDownModule,
    TabsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient],

      },
      defaultLanguage: 'en-US'}),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      autoDismiss: false,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    QRCodeModule    
  ],
  providers: [ CookieService,   { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  let element = <HTMLInputElement>document.getElementById("LayoutModel");  
  let buildNumberElement = <HTMLInputElement>document.getElementById("BuildNumber");
  let buildNumber = buildNumberElement ? buildNumberElement.value: '';
  if (buildNumber == "#{BuildNumber}#"){
    buildNumber = '';
  }
  if (buildNumber){
    buildNumber = "/" + buildNumber ;
  }
  const layoutView = <LayoutViewModel>JSON.parse(element.value);
  if (!layoutView.cdnPath){
    layoutView.cdnPath = '';
  }
  layoutView.cdnPath = layoutView.cdnPath+ buildNumber 
  return new TranslateHttpLoader(http, layoutView.cdnPath+'/assets/i18n/', '.json');
}
