import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AppService } from '../app.service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
export class MultiFactorService {
    constructor(httpClient, appService) {
        this.httpClient = httpClient;
        this.appService = appService;
    }
    login(request) {
        return this.httpClient.post("/Account/MfaLogin", request).pipe(catchError((error) => {
            this.appService.errorObject = [error.error];
            return throwError(error);
        }));
    }
    requestToken(request) {
        return this.httpClient.post("/Account/RequestCode", request).pipe(catchError((error) => {
            this.appService.errorObject = [error.error];
            return throwError(error);
        }));
    }
}
MultiFactorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiFactorService_Factory() { return new MultiFactorService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService)); }, token: MultiFactorService, providedIn: "root" });
